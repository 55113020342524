import { Fragment, useEffect, useReducer, useState } from 'react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from '../../hooks';

import { ArrowDownUp } from 'react-bootstrap-icons';
import { RadialChart } from 'react-vis';

import { DateRange } from '../../components/common/DateSelector';
import { GamePeriodPicker } from '../../components/common/GamePeriodPicker'
import { SpreadRange } from '../../components/common/SpreadFilter';
import { GAME_ODDS, TeamData, VALID_SPORTS } from '../../models';
import { SUB_PAGE, RESULT, VENUE, GameData, GAME_PERIODS, HOCKEY_GAME_PERIODS } from '../../models/common';
import { AwayTeamLoss, AwayTeamWin, Card, HomeTeamLoss, HomeTeamWin, LoadingOverlay } from '../../components/common';
import { mapSportToApiKey, mapSubPageAndTimeDivisionToApiKey } from '.';

import '../../styles/page.scss';

export interface StreakData {
  isWin: boolean,
  length: number,
  currentStreakData: { isHome: boolean; pushStreak?: number; gameId: string }[];
}
export interface SportStreakProps {
  sport: VALID_SPORTS,
  subPage: SUB_PAGE,
  currentOdds: GAME_ODDS,
  dateRange: DateRange,
  setDateRange: (range: DateRange) => void,
  spreadRange: SpreadRange,
  allTeams?: TeamData[],
  getGamesByTeamIdQuery: UseQuery<QueryDefinition<{teamId: string, marketKey: string, sportKey: string, league: string, includePrices: boolean}, any, never, GameData[], string>>;
}

interface TeamStreakProps {
  sport: VALID_SPORTS
  subPage: SUB_PAGE,
  teamId: string,
  selectedTeam?: TeamData,
  currentOdds: GAME_ODDS,
  dateRange: DateRange,
  spreadRange: SpreadRange
  setSummaryData: (winStreak: number, lossStreak: number) => void,
  getGamesByTeamIdQuery: UseQuery<QueryDefinition<{teamId: string, marketKey: string, sportKey: string, league: string, includePrices: boolean}, any, never, GameData[], string>>,
  selectedTimePeriod: GAME_PERIODS | HOCKEY_GAME_PERIODS
}

interface TeamSummaryProps {
  subPage?: SUB_PAGE,
  teamId: string,
  teamName: string,
  winsStreak: number,
  lossesStreak: number,
  maxOverallStreak?: number
}

const TeamSummary = (props: TeamSummaryProps) => {
  const {subPage, teamName, winsStreak, lossesStreak, maxOverallStreak } = props;

  const maxStreak = winsStreak >= lossesStreak ? winsStreak : lossesStreak;

  return (
    <div className='team-summary'>
      <div className='team-name'>{teamName}</div>
      <div
        style={{
          backgroundColor: winsStreak >= lossesStreak ? '#7eab46' : '#c33636',
          width: maxOverallStreak ? `${(maxStreak / maxOverallStreak)*10}rem` : '10rem'
        }}
        className='streak-bar'
      ></div>
      <div className='streak-text'>{winsStreak >= lossesStreak ? `${winsStreak} ${subPage === SUB_PAGE.TOTAL ? 'over' : 'wins'}` : `${lossesStreak} ${subPage === SUB_PAGE.TOTAL ? 'under' : 'losses'}`}</div>
    </div>
  );
}

const TeamStreak = (props: TeamStreakProps) => {
  const { sport, subPage, teamId, selectedTeam, setSummaryData, getGamesByTeamIdQuery, selectedTimePeriod } = props;

  const [streakData, setStreakData] = useState<StreakData[]>([]);
  const [totalWins, setTotalWins] = useState(0);
  const [totalLosses, setTotalLosses] = useState(0);
  const [totalPush, setTotalPush] = useState(0);
  const selectedDate = useAppSelector(({ teams }) => teams.selectedDate);

  const {
    data: allGameData,
    error: allGameError,
    isLoading: allGameLoading
  } = getGamesByTeamIdQuery({
    sportKey: mapSportToApiKey(sport).sportKey,
    league: mapSportToApiKey(sport).league,
    teamId,
    marketKey: mapSubPageAndTimeDivisionToApiKey(subPage, selectedTimePeriod),
    includePrices: false,
  });

  useEffect(() => {
    if (!allGameData || !allGameData.length) {
      return;
    }

    const sortedGameData = [...allGameData]
      // .filter(game => game.startTime.getTime() >= dateRange.start.getTime() && game.startTime.getTime() <= dateRange.end.getTime())
      .filter((game) => selectedDate ? new Date(game.startTime).getTime() < selectedDate : true)
      .sort((a, b) =>
        // Sort ascending
        new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );

    const streaks = [] as StreakData[];
    let currentStreakLength = 0;
    let currentWinStreak = true;
    let currentStreakData = [] as { isHome: boolean, pushStreak?: number, gameId: string }[];
    let sumTotalWins = 0;
    let sumTotalLosses = 0;
    let sumTotalPush = 0;

    sortedGameData.forEach(({ id, teams }) => {
      const gameData = teams[teamId];

      const result = gameData[mapSubPageAndTimeDivisionToApiKey(subPage, selectedTimePeriod)]?.result;

      if (!result) {
        return;
      }
      const gameIsWin = result === RESULT.WIN;
      const gameIsPush = result === RESULT.PUSH;
      const gameIsHome = gameData?.venue === VENUE.HOME;

      if (gameIsWin) {
        sumTotalWins += 1;
      } else if (!gameIsPush) {
        // TODO: If check if push counts as a loss or a no-op
        sumTotalLosses += 1;
      }

      if (gameIsPush) {
        sumTotalPush += 1;

        const lastStreakData = currentStreakData.pop();

        if (streaks.length && lastStreakData) {
          currentStreakData.push({
            ...lastStreakData,
            pushStreak: (lastStreakData.pushStreak || 0) + 1,
          });
        }

        return;
      }

      if (currentWinStreak === gameIsWin) {
        currentStreakLength += 1;
        currentStreakData.push({ isHome: gameIsHome, gameId: id });
      } else {
        streaks.push({
          isWin: currentWinStreak,
          length: currentStreakLength,
          currentStreakData: currentStreakData,
        });
        currentStreakLength = 1;
        currentWinStreak = gameIsWin;
        currentStreakData = [{ isHome: gameIsHome, gameId: id }];
      }
    });

    streaks.push({
      isWin: currentWinStreak,
      length: currentStreakLength,
      currentStreakData: currentStreakData,
    });

    setStreakData(streaks);

    // // Set streak data in team summary as longest streak ever
    // const maxWinStreak = streaks.filter(streak => streak.isWin).sort((a,b) => b.length - a.length)[0]?.length
    // const maxLossStreak = streaks.filter(streak => !streak.isWin).sort((a,b) => b.length - a.length)[0]?.length

    // Set streak data in team summary as most recent streak
    let maxWinStreak = 0;
    let maxLossStreak = 0;
    const mostRecentStreak = streaks[streaks.length - 1];
    if (mostRecentStreak.isWin) {
      maxWinStreak = mostRecentStreak.length;
    } else {
      maxLossStreak = mostRecentStreak.length;
    }

    setSummaryData(maxWinStreak, maxLossStreak);
    setTotalWins(sumTotalWins);
    setTotalLosses(sumTotalLosses);
    setTotalPush(sumTotalPush);
  }, [allGameData, subPage, selectedDate]);

  return (
    <>
      <div className='team-header'>
        <img src={selectedTeam?.logoPath} alt='' />
        <div>{selectedTeam?.name || teamId}</div>
      </div>

      <Card classList='mb-3 d-flex streak-card'>
        {
          <LoadingOverlay
            loading={allGameLoading}
            error={!!allGameError}
            noData={!allGameData?.length || (totalLosses ===0 && totalWins === 0 && totalPush === 0)}
          >
            <>
            <div className='donut-chart-container'>
                <div className='title'>{subPage === SUB_PAGE.TOTAL ? 'Over Percentage' : 'Winning Percentage'}</div>
                <div className='donut-chart'>
                  <div className='percent-legend'>{Math.round(100*(totalWins / (totalLosses + totalWins + totalPush)))}%</div>
                  <RadialChart
                    radius={50}
                    innerRadius={35}
                    width={120}
                    height={120}
                    //@ts-ignore
                    data={[
                      {angle: totalLosses + totalPush, style: {fill: 'transparent', stroke: 'transparent'}},
                      {angle: totalWins, label: 'Total Wins', style:{fill: '#7eab46', stroke: '#ffffff'}},
                    ]}
                  />
                </div>
                <div className='winStats'>
                  {totalWins}-{totalLosses}-{totalPush}
                </div>
              </div>

              <div className='streak-chart-container'>
                {streakData.map((streak, idx) => (
                  <div key={idx} className='streak-column'>
                    {streak.currentStreakData.map(({ isHome, pushStreak, gameId }, idx) => (
                        idx > 6
                        ? <Fragment key={idx} />
                        : idx > 5
                        ? <div key={idx} onClick={() => navigator.clipboard.writeText(streak.currentStreakData.map(({gameId}) => gameId).join(', '))}>+{streak.length - idx}</div>
                        : streak.isWin && isHome
                        ? <HomeTeamWin number={pushStreak} key={idx} onClick={() => navigator.clipboard.writeText(gameId)}/>
                        : streak.isWin && !isHome
                        ? <AwayTeamWin number={pushStreak} key={idx} onClick={() => navigator.clipboard.writeText(gameId)}/>
                        : !streak.isWin && isHome
                        ? <HomeTeamLoss number={pushStreak} key={idx} onClick={() => navigator.clipboard.writeText(gameId)}/>
                        : <AwayTeamLoss number={pushStreak} key={idx} onClick={() => navigator.clipboard.writeText(gameId)}/>
                      ))}
                  </div>
                ))}
              </div>
            </>
          </LoadingOverlay>
        }
      </Card>
    </>
  );
};

const teamSummaryReducer = (
  state: { [teamId: string]: TeamSummaryProps },
  action: { type: 'setTeamData'; payload: TeamSummaryProps }
) => {
  if (action.type === 'setTeamData') {
    return {
      ...state,
      [action.payload.teamId]: action.payload
    };
  }
  return { ...state };
};

export const Streaks = (props: SportStreakProps) => {
  const { sport, subPage, currentOdds, dateRange, setDateRange, spreadRange, allTeams, getGamesByTeamIdQuery} = props;

  const currentGamePeriods = sport === 'NHL'
      ? HOCKEY_GAME_PERIODS
      : GAME_PERIODS;

  const [ teamSummaryState, teamSummaryDispatch ] = useReducer(teamSummaryReducer, {} as {[teamId: string]: TeamSummaryProps});
  const [maxStreak, setMaxStreak] = useState(0);
  const [sortTeamSummaryData, setSortTeamSummaryData] = useState<boolean>(true);
  const selectedTeams = useAppSelector(({ teams }) => teams.teams);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(currentGamePeriods.FULL_TIME);

  useEffect(() => {
    // If we're coming from the Line Movement page, load the previous date range
    const previousDateRange = JSON.parse(localStorage.getItem('prevDateRange') || 'null');

    if (previousDateRange) {
      const range = {
        start: new Date(previousDateRange.start),
        end: new Date(previousDateRange.end),
      };

      setDateRange(range);
    }

    localStorage.removeItem('prevDateRange');
  }, []);

  return (
    <>
      <GamePeriodPicker gamePeriods={currentGamePeriods} selectedTimePeriod={selectedTimePeriod} setSelectedTimePeriod={setSelectedTimePeriod} />
      <div className='streaks-container w-100'>
        {Object.values(selectedTeams).reduce((acc, val) => acc && !val, true) ? (
          <div className='no-teams-text'>No teams selected</div>
        ) : (
          <>
            <div className='summary-container'>
              <div className='summary-title-bar'>
                <div>Team Results</div>
                <div
                  className='filter-button'
                  onClick={() => setSortTeamSummaryData(!sortTeamSummaryData)}
                  >
                  Filter By Streaks <ArrowDownUp />
                </div>
              </div>
              {Object.keys(selectedTeams)
                  .filter(teamId => selectedTeams[teamId])
                  .sort((a,b) => {
                    const maxStreaks = [a, b].map(teamId => Math.max(
                      teamSummaryState[teamId]?.winsStreak || 0,
                      teamSummaryState[teamId]?.lossesStreak || 0
                    ))

                    return maxStreaks[Number(sortTeamSummaryData)] - maxStreaks[Number(!sortTeamSummaryData)]
                  })
                  .map((teamId) => {
                    const teamData = allTeams?.find((team) => team.id === teamId);

                    if (!teamData) {
                      return <Fragment key={teamId} />;
                    }

                    return (
                      <TeamSummary
                        subPage={subPage}
                        key={teamId}
                        teamId={teamId}
                        teamName={teamData.name || teamId}
                        winsStreak={teamSummaryState[teamId]?.winsStreak || 0}
                        lossesStreak={teamSummaryState[teamId]?.lossesStreak || 0}
                        maxOverallStreak={maxStreak}
                      />
                    );
                  })}
            </div>
            <div className='team-streaks-container'>
              {Object.keys(selectedTeams)
                .filter((teamId) => selectedTeams[teamId])
                .sort((a, b) => {
                  const maxStreaks = [a, b].map((teamId) =>
                    Math.max(
                      teamSummaryState[teamId]?.winsStreak || 0,
                      teamSummaryState[teamId]?.lossesStreak || 0
                    )
                  );

                  return maxStreaks[Number(sortTeamSummaryData)] - maxStreaks[Number(!sortTeamSummaryData)];
                })
                .map((teamId: string) => (
                  <TeamStreak
                    sport={sport}
                    subPage={subPage}
                    key={teamId}
                    teamId={teamId}
                    selectedTeam={allTeams?.find((team) => team.id === teamId)}
                    currentOdds={currentOdds}
                    dateRange={dateRange}
                    spreadRange={spreadRange}
                    setSummaryData={(winStreak: number, lossStreak: number) => {
                      if (Math.max(winStreak, lossStreak) > maxStreak) {
                        setMaxStreak(Math.max(winStreak, lossStreak));
                      }

                      teamSummaryDispatch({
                        type: 'setTeamData',
                        payload: {
                          teamId: teamId,
                          teamName: allTeams?.find(team => team.id === teamId)?.name || teamId,
                          winsStreak: winStreak,
                          lossesStreak: lossStreak,
                        },
                      });
                    }}
                    getGamesByTeamIdQuery={getGamesByTeamIdQuery}
                    selectedTimePeriod={selectedTimePeriod}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};
