import './index.scss';
import { GAME_PERIODS, HOCKEY_GAME_PERIODS } from '../../../models/common';

interface GamePeriodPickerProps {
  gamePeriods: typeof GAME_PERIODS | typeof HOCKEY_GAME_PERIODS;
  selectedTimePeriod: GAME_PERIODS | HOCKEY_GAME_PERIODS;
  setSelectedTimePeriod:  React.Dispatch<React.SetStateAction<GAME_PERIODS | HOCKEY_GAME_PERIODS>>;
}

export const GamePeriodPicker = (props: GamePeriodPickerProps) => {
  const { gamePeriods, selectedTimePeriod, setSelectedTimePeriod } = props;

  return (
    <div className='game-dividion-selector'>
        {
          Object.values(gamePeriods).map((period) => (
            <div
              key={period}
              className={`game-dividion-tab ${selectedTimePeriod === period ? 'selected' : ''}`}
              onClick={() => { setSelectedTimePeriod(period) }}
            >
              {period}
            </div>
          ))
        }
    </div>
  );
}
