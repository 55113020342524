import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BetsData, GameData, DataExplorerGameData } from '../models/common'

export const gameApi = createApi({
  reducerPath: 'gameApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'https://wager-trends-sql.azurewebsites.net/api'
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://wager-trends-http-2-gddehje4ddbmbqhf.eastus-01.azurewebsites.net/api' : process.env.PUBLIC_URL + '/api'
  }),
  endpoints: (builder) => ({
    getGamesOnly: builder.query<GameData[], { sportKey: string, league: string }>({
      query: ({ sportKey, league }) => `games?sportKey=${sportKey}&league=${league}`
    }),

    getAllGames: builder.query<GameData[], { marketKey: string, sportKey: string, league: string, includePrices: boolean, forceCache?: boolean, endDate?: string }>({
      query: ({ marketKey, sportKey, league, includePrices, forceCache = false, endDate }) => `games?includeBetData=true&timezone=America/Chicago&includePrices=${includePrices}&sportKey=${sportKey}&league=${league}&marketKey=${marketKey}${forceCache ? '&doCache=true' : ''}${endDate ? `&endDate=${endDate}` : ''}`,
      serializeQueryArgs: ({ queryArgs }) => `${queryArgs.marketKey}-${queryArgs.sportKey}-${queryArgs.league}-${queryArgs.includePrices}${queryArgs.endDate ? `-${queryArgs.endDate}` : ''}`
    }),

    getGameById: builder.query<GameData, { gameId: string, marketKey: string, sportKey: string, league: string, includePrices: boolean }>({
      query: ({gameId, marketKey, sportKey, league, includePrices}) => `games?includeBetData=true&includePrices=${includePrices}&sportKey=${sportKey}&league=${league}&gameId=${gameId}&marketKey=${marketKey}`,
      serializeQueryArgs: (args) => `${args.queryArgs.gameId}-${args.queryArgs.marketKey}-${args.queryArgs.sportKey}-${args.queryArgs.includePrices}`
    }),

    getGamesByTeamId: builder.query<GameData[], { teamId: string, marketKey: string, sportKey: string, league: string, includePrices: boolean }>({
      query: ({teamId, marketKey, sportKey, league, includePrices}) => `games?includeBetData=true&includePrices=${includePrices}&sportKey=${sportKey}&league=${league}&teamId=${teamId}&marketKey=${marketKey}`,
      serializeQueryArgs: (args) => `${args.queryArgs.teamId}-${args.queryArgs.marketKey}-${args.queryArgs.sportKey}-${args.queryArgs.includePrices}`
    }),

    getRawGameData: builder.query<DataExplorerGameData, { gameId: string, marketKey: string, sportKey: string, league: string }>({
      query: ({gameId, marketKey, sportKey, league}) => `games?includeBetData=true&sportKey=${sportKey}&league=${league}&gameId=${gameId}&raw=true&marketKey=${marketKey}`,
    }),

    getBetsByTeamId: builder.query<BetsData[], { teamId: string, marketKeys: string[], sportKey: string, league: string }>({
      query: ({ teamId, marketKeys, sportKey, league }) => `games/bets?includeBetData=true&doCache=true&teamId=${teamId}&marketKey=${marketKeys.join(',')}&sportKey=${sportKey}&league=${league}`
    })

    // TODO: Make a query that gets by parameter so we don't load all team data every time
  })
})

export const {
  useGetGamesOnlyQuery,
  useGetAllGamesQuery,
  useGetGameByIdQuery,
  useGetGamesByTeamIdQuery,
  useGetRawGameDataQuery,
  useGetBetsByTeamIdQuery
} = gameApi