import { GAME_ODDS } from '../../../models';
import './OddsSelector.scss';

export interface OddsSelectorProps {
  selectedOdds: GAME_ODDS;
  onSelectOdds?: (selectedOdds: GAME_ODDS) => void;
}

export const OddsSelector = (props: OddsSelectorProps) => {
  const { selectedOdds, onSelectOdds } = props;

  const handleClickOdds = (odds: GAME_ODDS) => {
    onSelectOdds && onSelectOdds(odds);
  };

  return (
    <div className='odds-selector'>
      <ul className='odds-list'>
        {Object.values(GAME_ODDS).map(odds => (
          <li
            key={odds}
            onClick={() => handleClickOdds(odds)}
            className={`odds ${odds === selectedOdds ? 'selected' : ''}`}
          >
            {odds}
          </li>
        ))}
      </ul>
    </div>
  );
}