import { useEffect, useState } from 'react';
import { SpreadTable } from '../../components/common';
import { GameData } from '../../models/common';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { TableData } from '../../components/common/SpreadTable';

interface TrackerProps {
  gamesData: GameData[];
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError | undefined;
  selectedDate: Date;
}

export const Tracker = (props: TrackerProps) => {
  const { gamesData, isLoading, error, selectedDate } = props;
  const [ formattedGamesData, setFormattedGamesData ] = useState<Record<string, TableData>>({});

  useEffect(() => {
    setFormattedGamesData({});
    const tempFormattedGamesData: Record<string, TableData> = {};

    gamesData.forEach(({ teams, startTime }) => {
      const gameDate = new Date(startTime);

      Object.keys(teams).forEach((teamName) => {
        const team = teams[teamName];
        const isHome = team.venue === 'Home';

        if (!tempFormattedGamesData[teamName]) {
          tempFormattedGamesData[teamName] = {
            isHome,
            spread: team.spreads ? team.spreads.point.current : null,
            mostRecentDate: gameDate,
            spreadRecord: { wins: 0, losses: 0, pushes: 0 },
            homeSpreadRecord: { wins: 0, losses: 0, pushes: 0 },
            awaySpreadRecord: { wins: 0, losses: 0, pushes: 0 }
          };
        } else if (gameDate > tempFormattedGamesData[teamName].mostRecentDate) {
          tempFormattedGamesData[teamName].mostRecentDate = gameDate;
          tempFormattedGamesData[teamName].spread = team.spreads ? team.spreads.point.current : null;
        }

        if (team.spreads?.result === 'Win') {
          tempFormattedGamesData[teamName].spreadRecord.wins += 1;
          tempFormattedGamesData[teamName][isHome ? 'homeSpreadRecord' : 'awaySpreadRecord'].wins += 1;
        } else if (team.spreads?.result === 'Loss') {
          tempFormattedGamesData[teamName].spreadRecord.losses += 1;
          tempFormattedGamesData[teamName][isHome ? 'homeSpreadRecord' : 'awaySpreadRecord'].losses += 1;
        } else if (team.spreads?.result === 'Push') {
          tempFormattedGamesData[teamName].spreadRecord.pushes += 1;
          tempFormattedGamesData[teamName][isHome ? 'homeSpreadRecord' : 'awaySpreadRecord'].pushes += 1;
        }
      });
    });

    setFormattedGamesData(
      Object
      .entries(tempFormattedGamesData)
      .reduce((acc, [teamName, teamData]) => {
        if (teamData.mostRecentDate.getTime() >= selectedDate.getTime()) {
          acc[teamName] = teamData;
        }

        return acc;
      }, {} as Record<string, TableData>)
    );
  }, [gamesData, props, selectedDate]);

  return (<>
    <div style={{ margin: '40px 10%' }}>
      {isLoading
        ? <div>Loading...</div>
        : error
          ? <div>There has been an error. Please try again later.</div>
          : Object.keys(formattedGamesData).length === 0
            ?  <div>No game data found for the selected sport and date.</div>
            : <SpreadTable data={formattedGamesData} />
      }
    </div>
  </>);
};