import { useEffect, useState } from 'react';
import { GamePeriodPicker, SpreadTable } from '../../components/common';
import { GameData, GamePeriods, GENERAL_GAME_PERIODS, SUB_PAGE } from '../../models/common';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { TableData } from '../../components/common/SpreadTable';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { mapSportToApiKey, mapSubPageAndTimeDivisionToApiKey } from '.';
import { VALID_SPORTS } from '../../models';
import { CircularProgress } from '@mui/material';

interface TrackerProps {
  sport: VALID_SPORTS;
  selectedDate: Date;
  getAllGames: UseQuery<QueryDefinition<{ marketKey: string; sportKey: string; league: string; includePrices: boolean; endDate?: string; }, any, never, GameData[], string>>
}

export const Tracker = (props: TrackerProps) => {
  const { selectedDate, getAllGames, sport } = props;

  const [formattedGamesData, setFormattedGamesData] = useState<Record<string, TableData>>({});
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<GamePeriods>(GENERAL_GAME_PERIODS.FULL_TIME);

  const marketKey = mapSubPageAndTimeDivisionToApiKey(SUB_PAGE.SPREAD, selectedTimePeriod);

  const { data: gamesData, error, isFetching: isLoading } = getAllGames({
    marketKey,
    sportKey: mapSportToApiKey(sport).sportKey,
    league: mapSportToApiKey(sport).league,
    includePrices: true,
    endDate: `${new Date(selectedDate).getFullYear()}-${String(new Date(selectedDate).getMonth() + 1).padStart(2, '0')}-${String(new Date(selectedDate).getDate()).padStart(2, '0')} 00:00:00`
  });

  useEffect(() => {
    setFormattedGamesData({});
    const tempFormattedGamesData: Record<string, TableData> = {};

    gamesData?.forEach(({ teams, startTime }) => {
      const gameDate = new Date(startTime);

      Object.keys(teams).forEach((teamName) => {
        const team = teams[teamName];
        const isHome = team.venue === 'Home';

        const spreadData = team[marketKey];

        if (!tempFormattedGamesData[teamName]) {
          tempFormattedGamesData[teamName] = {
            isHome,
            spread: spreadData?.point.current ?? null,
            mostRecentDate: gameDate,
            spreadRecord: { wins: 0, losses: 0, pushes: 0 },
            homeSpreadRecord: { wins: 0, losses: 0, pushes: 0 },
            awaySpreadRecord: { wins: 0, losses: 0, pushes: 0 }
          };
        } else if (gameDate > tempFormattedGamesData[teamName].mostRecentDate) {
          tempFormattedGamesData[teamName].mostRecentDate = gameDate;
          tempFormattedGamesData[teamName].spread = spreadData?.point.current ?? null;
        }

        if (spreadData?.result === 'Win') {
          tempFormattedGamesData[teamName].spreadRecord.wins += 1;
          tempFormattedGamesData[teamName][isHome ? 'homeSpreadRecord' : 'awaySpreadRecord'].wins += 1;
        } else if (spreadData?.result === 'Loss') {
          tempFormattedGamesData[teamName].spreadRecord.losses += 1;
          tempFormattedGamesData[teamName][isHome ? 'homeSpreadRecord' : 'awaySpreadRecord'].losses += 1;
        } else if (spreadData?.result === 'Push') {
          tempFormattedGamesData[teamName].spreadRecord.pushes += 1;
          tempFormattedGamesData[teamName][isHome ? 'homeSpreadRecord' : 'awaySpreadRecord'].pushes += 1;
        }
      });
    });

    setFormattedGamesData(
      Object
      .entries(tempFormattedGamesData)
      .reduce((acc, [teamName, teamData]) => {
        if (teamData.mostRecentDate.getTime() >= selectedDate.getTime()) {
          acc[teamName] = teamData;
        }

        return acc;
      }, {} as Record<string, TableData>)
    );
  }, [gamesData, props, selectedDate]);

  return (<>
    <GamePeriodPicker
      selectedTimePeriod={selectedTimePeriod}
      setSelectedTimePeriod={setSelectedTimePeriod}
      sport={sport}
    />
    <div style={{ margin: '40px 10%' }}>
      {isLoading
        ? <CircularProgress />
        : error
          ? <div>There has been an error. Please try again later.</div>
          : Object.keys(formattedGamesData).length === 0
            ?  <div>No game data found for the selected sport and date.</div>
            : <SpreadTable data={formattedGamesData} />
      }
    </div>
  </>);
};