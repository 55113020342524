import './index.scss';
import { GamePeriods, GENERAL_GAME_PERIODS, HOCKEY_GAME_PERIODS } from '../../../models/common';
import { VALID_SPORTS } from '../../../models';

interface GamePeriodPickerProps {
  selectedTimePeriod: GamePeriods;
  setSelectedTimePeriod:  React.Dispatch<React.SetStateAction<GamePeriods>>;
  sport: VALID_SPORTS;
}

export const GamePeriodPicker = (props: GamePeriodPickerProps) => {
  const { selectedTimePeriod, setSelectedTimePeriod, sport } = props;
  const currentGamePeriods = sport === 'NHL'
    ? HOCKEY_GAME_PERIODS
    : GENERAL_GAME_PERIODS;

  return (
    <div className='game-dividion-selector'>
        {
          Object.values(currentGamePeriods).map((period) => (
            <div
              key={period}
              className={`game-dividion-tab ${selectedTimePeriod === period ? 'selected' : ''}`}
              onClick={() => { setSelectedTimePeriod(period) }}
            >
              {period}
            </div>
          ))
        }
    </div>
  );
}
